import { useState } from 'react';
import axios from 'axios';

import settings from '../config';
import logo from '../assets/logo.png';
import resurrection from '../assets/resurrection.jpeg';

const Days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const today = new Date();
const todayIndex =
  today.getHours() >= 12
    ? new Date(today).getDay() + 1
    : new Date(today).getDay();

let liturgy;
let liturgyDate;

if (todayIndex > 3) {
  liturgy = 0;
  liturgyDate = new Date(new Date().setDate(today.getDate() + 7 - todayIndex));
} else if (todayIndex > 0 && todayIndex <= 3) {
  liturgy = 3;
  liturgyDate = new Date(new Date().setDate(today.getDate() + 3 - todayIndex));
} else {
  liturgy = 0;
  liturgyDate = new Date(today);
}

const Congregation = () => {
  const [formData, setFormData] = useState({
    name: '',
    liturgy: liturgyDate.toISOString().replace('T', ' ').slice(0, 19),
    category: ''
  });
  const [isSent, setIsSent] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (formData.name && formData.category && formData.liturgy) {
      const response = await axios.post(
        `${settings.api}names/create`,
        formData
      );

      if (response.data.data) {
        setIsSent(true);
        setFormData({
          ...formData,
          name: '',
          category: ''
        });
      }
    }
  };

  const handleOnChange = (e) => {
    setIsSent(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div
      className='text-2xl bg-cover bg-center h-full text-textPrimary'
      style={{
        backgroundImage: `url(${resurrection})`
      }}
    >
      <div className='absolute top-0 left-0 w-full h-full bg-black/80'></div>
      <img
        className='h-24 absolute'
        src={logo}
        alt='St Mary and St Abraam Logo'
      />
      <div className='absolute top-0 left-0 w-full h-full p-10'>
        <div className='flex items-center justify-center h-full'>
          <form className='flex gap-10 flex-col' onSubmit={handleOnSubmit}>
            <div className='flex items-center justify-center'>
              Commemoration - تراحيم
            </div>
            <label htmlFor='name' className='flex flex-col gap-4'>
              <span>Name - الاسم</span>
              <input
                className='p-2 rounded-xl text-primary'
                placeholder='name'
                type='text'
                id='name'
                name='name'
                value={formData.name}
                onChange={handleOnChange}
              />
            </label>

            <label htmlFor='liturgy' className='flex flex-col gap-4'>
              <span>Liturgy - القداس</span>
              <input
                className='p-2 rounded-xl text-primary'
                disabled
                placeholder='Liturgy'
                type='text'
                id='liturgy'
                name='liturgy'
                value={`${Days[liturgy]} - ${liturgyDate
                  .toISOString()
                  .replace('T', '')
                  .slice(0, 10)}`}
                onChange={handleOnChange}
              />
            </label>

            <label htmlFor='category' className='flex flex-col gap-4'>
              <span>Category - التصنيف</span>
              <select
                className='p-2 rounded-xl text-primary'
                id='category'
                name='category'
                onChange={handleOnChange}
                defaultValue={formData.category}
                value={formData.category}
              >
                <option value=''>Please Select Category ...</option>
                <option value='Recent'>Recent</option>
                <option value='40 Days'>40 Days</option>
                <option value='Annual'>Annual</option>
                <option value='General'>General</option>
              </select>
            </label>

            <button
              className='border rounded-xl p-2 bg-textPrimary/20 hover:bg-textPrimary/10'
              type='submit'
            >
              Submit - ارسال
            </button>

            {isSent && (
              <div className='text-green-300'>Name Submitted Successfully</div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Congregation;
