import QRCode from './assets/qrcode.svg';
import './output.css';

function App() {
  return (
    <div className='flex justify-center items-center w-full h-full'>
      <img src={QRCode} className='h-full' alt='SMSA Commemoration QR Code' />
    </div>
  );
}

export default App;
