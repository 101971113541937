import { useEffect, useState } from 'react';
import axios from 'axios';

import settings from '../config';
import logo from '../assets/logo.png';
import resurrection from '../assets/resurrection.jpeg';

const Priest = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await axios.get(`${settings.api}names`);
      setList(response.data.data);
    })();
  }, []);

  const handleOnDelete = async (id) => {
    const response = await axios.post(`${settings.api}names/delete`, {
      id
    });

    if (response.data.data === 1) {
      setList(list.filter((name) => name.id !== id));
    }
  };

  if (!list?.length) {
    return (
      <div
        className='text-2xl bg-cover bg-center bg-fixed min-h-full text-textPrimary'
        style={{
          backgroundImage: `url(${resurrection})`
        }}
      >
        <div className='absolute top-0 left-0 w-full min-h-full bg-black/80'></div>
        <img
          className='h-24 absolute'
          src={logo}
          alt='St Mary and St Abraam Logo'
        />
      </div>
    );
  }

  return (
    <div
      className='flex h-auto min-h-full text-2xl bg-cover bg-center bg-fixed  text-textPrimary'
      style={{
        backgroundImage: `url(${resurrection})`
      }}
    >
      <div className='w-full  p-10 bg-black/80'>
        <img className='h-24' src={logo} alt='St Mary and St Abraam Logo' />
        <div className='flex flex-col gap-10 items-center justify-center '>
          {list.filter((l) => l.category === 'Recent').length ? (
            <div>
              <div className=''>Recent Commemorations</div>
              <ol className=' list list-decimal ml-10'>
                {list
                  .filter((l) => l.category === 'Recent')
                  .map((name) => {
                    return (
                      <li key={name.id}>
                        <div className='flex justify-between'>
                          <span>{name.name}</span>
                          <span
                            className='text-rose-900 cursor-pointer'
                            onClick={() => handleOnDelete(name.id)}
                          >
                            x
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ol>
            </div>
          ) : null}

          {list.filter((l) => l.category === '40 Days').length ? (
            <div>
              <div>40 Days Commemorations</div>
              <ol className=' list list-decimal ml-10'>
                {list
                  .filter((l) => l.category === '40 Days')
                  .map((name) => {
                    return (
                      <li key={name.id}>
                        <div className='flex justify-between'>
                          <span>{name.name}</span>
                          <span
                            className='text-rose-900 cursor-pointer'
                            onClick={() => handleOnDelete(name.id)}
                          >
                            x
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ol>
            </div>
          ) : null}

          {list.filter((l) => l.category === 'Annual').length ? (
            <div>
              <div>Annual Commemorations</div>
              <ol className=' list list-decimal ml-10'>
                {list
                  .filter((l) => l.category === 'Annual')
                  .map((name) => {
                    return (
                      <li key={name.id}>
                        <div className='flex justify-between'>
                          <span>{name.name}</span>
                          <span
                            className='text-rose-900 cursor-pointer'
                            onClick={() => handleOnDelete(name.id)}
                          >
                            x
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ol>
            </div>
          ) : null}

          {list.filter((l) => l.category === 'General').length ? (
            <div>
              <div>General Commemorations</div>
              <ol className=' list list-decimal ml-10'>
                {list

                  .filter((l) => l.category === 'General')
                  .map((name) => {
                    return (
                      <li key={name.id}>
                        <div className='flex justify-between'>
                          <span>{name.name}</span>
                          <span
                            className='text-rose-900 cursor-pointer'
                            onClick={() => handleOnDelete(name.id)}
                          >
                            x
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ol>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Priest;
